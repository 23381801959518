import React, {useState} from 'react'
import "./Css/login.css"
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Login = () => {

  const [phoneValue, setPhoneValue] = useState(); 
  const [otpMessage, setOtpMessage] = useState(); //Example OTP sent

  const [otpValue, setOtpValue] = useState(); 
  const [otpVerificationMessage, setOtpVerificationMessage] = useState();

  const navigate=useNavigate();

  const handlePhoneChange = (event) => {
    setPhoneValue(event.target.value);
    setOtpMessage('')
    };

    const handleOtpChange = (event) => {
      setOtpValue(event.target.value);
      setOtpVerificationMessage('')
      };

  

    const api_local = axios.create({
      baseURL:'http://localhost:8000'
  })

  const api_prod = axios.create({
    baseURL:'https://inmyapp-621171ea8d31.herokuapp.com'
})

  const api = api_prod

    const handleSubmitPhone = async (e) => {
      e.preventDefault();
      const response = await api.post('/chat',{message:'send OTP to this number - '+phoneValue});
      if([1,2,3].includes(response.data['code'])){
        setOtpMessage(response.data['message'])
      } else {
      setOtpMessage(response.data)
      }
        
      }

      const handleSubmitOtp = async (e) => {
        e.preventDefault();
        const response = await api.post('/chat',{message:'OTP is '+otpValue+'and phone number is '+phoneValue+'. Verify the phone number.'});
        if([1,2,4].includes(response.data['code'])){
          setOtpVerificationMessage(response.data['message'])
        } else {
        //console.log(response.data)

        sessionStorage['user']=response.data.data
        navigate("/dashboard")

        }
          
        }

    return (
        <>  
        <div className="main">
        <div className="body">
            <div><br></br></div>
  <div className="logincontainer">
  <h2 className="highlight">Login</h2>
    <div className="section section-phone">
      <label htmlFor="phone">Enter 10-digit Phone Number</label>
      <div className="input-group">
        <input type="tel" id="phone" name="phone"  onChange={handlePhoneChange} placeholder="Enter your phone number" maxLength="10" />
        <button type="submit" onClick={handleSubmitPhone}>Send OTP</button>
      </div>
      <div className="error-message">{otpMessage}</div>
    </div>
    <div className="section section-otp">
      <label htmlFor="otp">Enter OTP Sent by Telegram bot</label>
      <div className="input-group">
        <input type="number" id="otp" name="otp" onChange={handleOtpChange} placeholder="Enter OTP" maxLength="4" />
        <button type="submit" onClick={handleSubmitOtp}>Verify Now</button>
      </div>
      <div className="error-message">{otpVerificationMessage}</div>
    </div>
  </div>
  
</div>
<footer>
    <div>
      <a href="mailto:ivdmahesh@gmail.com">Email: ivdmahesh@gmail.com</a>
    </div>
    <div>
      <a href="tel:+919849502694">Phone: +91-9849502694</a>
    </div>
    <div>
                Copyright © 2025
            </div>
  </footer>
        </div>
        </>
    )

}

export default Login;