import React from 'react'

const Error = () => {

    return (
        <>
        <div>This is Error</div>
        </>
    )

}

export default Error;