import React from 'react'
import logo from './Images/logo.png';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import "./Css/headers.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Headers = () => {

    const navigate = useNavigate();

    const logout=()=>{
        sessionStorage.removeItem('user');
        //navigate("/")
    }

    return (
        <header>
        <div>
            <div> <img src={logo} width={100} height={100} alt=""/> </div>
            <div>

            <ul>
            <li><NavLink to="/">Home</NavLink></li>
            {

    sessionStorage['user'] ? (<>
    <li><NavLink to="/dashboard">Dashboard</NavLink></li><li onClick={logout}><NavLink to="/login">Logout</NavLink></li></>):<li><NavLink to="/login">Login</NavLink></li>
}
</ul>

            </div>
            <ToastContainer 
        position="top-right" // Position of the toast
        autoClose={5000} // Auto close time in milliseconds
        hideProgressBar={false} // Show the progress bar
        newestOnTop={false}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
        </div>
        </header>
    )

}

export default Headers;