import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {BounceLoader} from 'react-spinners';
import "./Css/dashboard.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {

    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [likescurrentPage, setLikesCurrentPage] = useState(1);
    const [totalpages, settotalpages] = useState(1);
    const [fetcheventmessage, setfetcheventmessage] = useState('');

    const [eventdata, setEventData] = useState([]);

    const api_local = axios.create({
        baseURL:'http://localhost:8000'
    })

    const api_prod = axios.create({
        baseURL:'https://inmyapp-621171ea8d31.herokuapp.com'
    })
  
    const api = api_prod

    const [isLoading, setIsLoading] = useState(false);
    

    useEffect(() => {

        if(!sessionStorage['user']){

            navigate("/login")
        }
        else {
        const fetchData = async () => {
            setEventData([])
            window.scrollTo(0, 0);
            setIsLoading(true);
            const response = await api.post('/chat',{message:`fetch page ${currentPage} of events for user with id ${sessionStorage['user']}`});
            setEventData(response.data.data)
            settotalpages(response.data.totalpages)
            setfetcheventmessage(response.data.message)
            setIsLoading(false);
        }

        fetchData();
    }

    },[currentPage])



    const decrementcurrentPage = () => {
        if(currentPage>1){
        setCurrentPage(currentPage-1)
        }
    }

    const incrementcurrentPage = () => {
        if(currentPage<totalpages){
        setCurrentPage(currentPage+1)
        }
    }

    const toggleState = async (btn) => {
        btn.classList.toggle('LIKED');
        btn.textContent = btn.classList.contains('LIKED') ? 'LIKED' : 'LIKE';
        const button = document.querySelector('.likes[data-eventid=\''+btn.getAttribute('data-eventid')+'\']');
            if(btn.getAttribute('data-initialstate')==='true'){
            
            if(btn.textContent==='LIKE'){
            button.textContent = (parseInt(btn.getAttribute('data-likes'),10)-1).toString()+' Likes';
            }
            else{
                button.textContent = (parseInt(btn.getAttribute('data-likes'),10)).toString()+' Likes';
            }
            
        }
        else{
            // increment number of likes by 1
            // initially not liked
            if(btn.textContent==='LIKED'){
            button.textContent = (parseInt(btn.getAttribute('data-likes'),10)+1).toString()+' Likes';
            }
            else{
                button.textContent = (parseInt(btn.getAttribute('data-likes'),10)).toString()+' Likes';
            }
            
        }
        

        await api.post('/chat',{message:`user ${sessionStorage['user']} has put a like for the event ${btn.getAttribute('data-eventid')}`});
    
    }

    const fetchlikes = async (btn) => {
        return await api.post('/chat',{message:`fetch likes for user with id ${sessionStorage['user']} and event ${btn.getAttribute('data-eventid')} and page ${likescurrentPage}`});
    }

    
    
    // Declare the handler outside of showcomments
const postButtonClick = async (btn) => {
    const eventid = btn.getAttribute('data-eventid');
    const user = sessionStorage['user']
    const commentInput = document.querySelector('.comment-input');
    const commentoverlay = document.querySelector('.comment-modal-overlay');

    const commentText = commentInput.value.trim();
    if (commentText) {
        const message = `user ${user} has commented on event ${eventid} as following - ${commentText}`
        api.post('/chat',{message:message});    
        toast.success('Your comment has been submitted successfully!');
        commentInput.value=''
        btn.textContent = (parseInt(btn.getAttribute('data-comments'),10)+1).toString()+' Comments';
        btn.setAttribute('data-comments',(parseInt(btn.getAttribute('data-comments'),10)+1).toString());
        commentoverlay.style.display = 'none';

    }
}

// Define a persistent handler function
const handlePostClick = (btn) => () => postButtonClick(btn);

    const showcomments = async (btn) => { 

        const commentoverlay = document.querySelector('.comment-modal-overlay');
    commentoverlay.style.display = 'flex';
    const commentcloseBtn = document.querySelector('.comment-close-btn');
    const postBtn = document.querySelector('.post-btn');

    commentcloseBtn.addEventListener('click', () => {
        commentoverlay.style.display = 'none';
    });

    

    // Close modal when clicking outside
    commentoverlay.addEventListener('click', (e) => {
        if (e.target === commentoverlay) {
            commentoverlay.style.display = 'none';
        }
    });

    // Remove the existing click listener before adding a new one
    postBtn.removeEventListener('click', postBtn._handlePostClick); 

    // Attach a new event listener with a persistent function
    postBtn._handlePostClick = handlePostClick(btn);
    postBtn.addEventListener('click', postBtn._handlePostClick);

    const eventid = btn.getAttribute('data-eventid');
    const user = sessionStorage['user'];
    const message=`fetch comments for user with id ${user} and event ${eventid} and page 1`
    const response = await api.post('/chat',{message:message});
    const comments = response.data.data;

    const usercommentListDiv = document.getElementById("comments-list-div");
    
    usercommentListDiv.innerHTML = comments.map(comment => `
        <div class="comment">
            <img src="${comment.profilepic}" alt="User Avatar" class="user-avatar">
            <div class="comment-content">
                <div class="comment-header">
                    <span class="user-name">${comment.name}</span>
                    <span class="user-description">${comment.description}</span>
                    <span class="comment-time">${comment.commentcreationtimeformatted}</span>
                    <span class="like-user-distance">${comment.distance/1000} km away</span>
                </div>
                <p class="comment-text">${comment.comment}</p>
                ${comment.userid.toString() === sessionStorage['user'] ? 
                    `<button class="delete-btn" data-comment="${comment.commentid}" data-event="${comment.eventid}">Delete</button>` : 
                    ''
                }
            </div>
        </div>
    `).join('');

    const deletecomment = async (btn) => {
        const commentid = btn.getAttribute('data-comment');
           const eventid = btn.getAttribute('data-event');

           const message =`user ${sessionStorage['user']} has deleted the comment with id ${commentid} for the event ${eventid}`;
           api.post('/chat',{message:message});
           toast.success('Comment has been deleted successfully')
}

    const deleteButtons = usercommentListDiv.querySelectorAll('.delete-btn');
    deleteButtons.forEach(button => {
        button.addEventListener('click', function() {
            deletecomment(this); // 'this' refers to the clicked button
        });
    });


    }

    

    const showuserswholiked = async (btn) => {
        
        const modal = document.getElementById("likeuserModal");
        const span = document.querySelector(".likes-close-btn");

         // Open modal
        modal.style.display = "flex";
         const likeslist = await fetchlikes(btn);

         // Close modal
        span.onclick = () => modal.style.display = "none";
        window.onclick = (event) => {
            if (event.target === modal) modal.style.display = "none";
        }

        const likeuserList = document.getElementById("likeuserList");
    
        likeuserList.innerHTML = likeslist.data.data.map(user => `<div class="like-user-card">
            <img src=${user.profilepic} alt=${user.name} class="like-user-image"/>
            <div class="like-user-info">
                <h3 class="like-user-name">${user.name}</h3>
                <p class="like-user-description">${user.description}</p>
                <p class="like-user-distance">${user.distance/1000} km away</p>
            </div>
        </div>`).join('');
    }

    let currentIndex = 0;
    const modalOverlay = document.querySelector('.modal-overlay');
    const modalImage = document.querySelector('.modal-image');
    const captionElement = document.querySelector('.caption');
    const progressIndicator = document.querySelector('.progress-indicator');

    const showimagesmodal = (target) => {
        if(JSON.parse(target.getAttribute('data-photos'))==null){
            return;
        }
        currentIndex = 0;
        updateModalContent(target);
        modalOverlay.classList.add('active');
    }

let currentImages = [];

function updateModalContent(target) {
    // Initialize modal content
    currentImages = JSON.parse(target.getAttribute('data-photos'));
    currentIndex = 0;
    refreshModalContent();

    // Add event listeners once
    const closeBtn = document.querySelector('.close-btn');
    const prevBtn = document.querySelector('.prev-btn');
    const nextBtn = document.querySelector('.next-btn');

    // Remove existing listeners to prevent duplicates
    closeBtn.removeEventListener('click', closeModal);
    modalOverlay.removeEventListener('click', overlayClickHandler);
    prevBtn.removeEventListener('click', showPrevImage);
    nextBtn.removeEventListener('click', showNextImage);
    document.removeEventListener('keydown', handleKeyDown);

    // Add fresh listeners
    closeBtn.addEventListener('click', closeModal);
    modalOverlay.addEventListener('click', overlayClickHandler);
    prevBtn.addEventListener('click', showPrevImage);
    nextBtn.addEventListener('click', showNextImage);
    document.addEventListener('keydown', handleKeyDown);
}

function refreshModalContent() {
    modalImage.src = currentImages[currentIndex].photo;
    captionElement.textContent = currentImages[currentIndex].caption;
    progressIndicator.textContent = `${currentIndex + 1}/${currentImages.length}`;
}

function showNextImage() {
    currentIndex = (currentIndex + 1) % currentImages.length;
    refreshModalContent();
}

function showPrevImage() {
    currentIndex = (currentIndex - 1 + currentImages.length) % currentImages.length;
    refreshModalContent();
}

function closeModal() {
    modalOverlay.classList.remove('active');
    // Cleanup references
    currentImages = [];
    currentIndex = 0;
}

function overlayClickHandler(e) {
    if (e.target === modalOverlay) closeModal();
}

function handleKeyDown(e) {
    if (modalOverlay.classList.contains('active')) {
        if (e.key === 'Escape') closeModal();
        if (e.key === 'ArrowLeft') showPrevImage();
        if (e.key === 'ArrowRight') showNextImage();
    }
}    

    

    return (
        <>
        <div className="main">
        <div className="loadercontainer">
            <div>
        {isLoading && (
           
           <BounceLoader color="darkgoldenrod" />
    
    )}
    </div>
    </div>
    <div>
    <ul>
        {eventdata.map((item, index) => (

            <li key={index}>
<div className="eventcontainer">
<div className="event-card">
    <div>
    <img src={item.eventpic} alt="Event Cover" className="cover-photo" data-photos={JSON.stringify(item.photos)} onClick={(e)=>showimagesmodal(e.target)}/>
    </div>
   
    <div className="event-meta">
            <span>Updated: {item.eventupdationtimeformatted}</span>
            <span>{item.distance/1000} km away</span>
        </div>
    
   
    <div className="likebody">
    <button className={`toggle-btn ${item.is_liked===true? 'LIKED':''}`} data-initialstate={item.is_liked.toString()} onClick={(e)=>toggleState(e.target)} data-eventid={item.eventid} data-likes={item.likes?? 0}>{`${item.is_liked===true? 'LIKED':'LIKE'}`}</button>
    <button className="likes" onClick={(e)=>{showuserswholiked(e.target)}} data-eventid={item.eventid}>{item.likes?? 0} Likes</button>
    <button className="comments" onClick={(e)=>{showcomments(e.target)}} data-eventid={item.eventid} data-comments={item.comments?? 0}>{item.comments?? 0} Comments</button>
</div>
</div>
</div>

</li>
        ))}
        </ul>
    </div>
    <div className="event-meta-center"><span>{fetcheventmessage}</span></div>
    <div className="pagination-container">
        
       { 
       
       currentPage !== 1 && (<button className="pagination-btn prev" onClick={() => decrementcurrentPage()}>Previous</button>)

       }
    {
    currentPage !== totalpages && (<button className="pagination-btn next" onClick={() => incrementcurrentPage()}>Next</button>)
}
</div>
{/*start of like user modal*/}
<div id="likeuserModal" className="likemodal">
        <div className="like-modal-content">
            <span className="likes-close-btn">&times;</span>
            <b>People who liked</b>
            <div id="likeuserList"></div>
        </div>
    </div>
    {/* start of image model */}
    <div className="modal-overlay">
        <div className="modal-content">
            <button className="close-btn">
                <svg viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                </svg>
            </button>
            <button className="nav-btn prev-btn">
                <svg viewBox="0 0 24 24">
                    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
                </svg>
            </button>
            <button className="nav-btn next-btn">
                <svg viewBox="0 0 24 24">
                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
                </svg>
            </button>
            <img className="modal-image" src="" alt=""/>
            <div className="caption"></div>
            <div className="progress-indicator"></div>
        </div>
    </div>
    {/*Comment model start*/}
    <div className="comment-modal-overlay">
    <div className="comment-modal">
    <div className="comment-modal-header">
                <h3 className="comment-modal-title">Comments</h3>
                <button className="comment-close-btn">&times;</button>
            </div>
            <div className="comment-modal-body">
            <div className="textareadiv">
            <textarea className="comment-input" placeholder="Write your comment..."></textarea>
                <button className="post-btn">Post Comment</button>
                </div>
                <div className="comments-list" id="comments-list-div"></div>
            </div>
    </div>
    </div>
    <footer>
    <div>
      <a href="mailto:ivdmahesh@gmail.com">Email: ivdmahesh@gmail.com</a>
    </div>
    <div>
      <a href="tel:+919849502694">Phone: +91-9849502694</a>
    </div>
                Copyright © 2025
  </footer>
    </div>
        </>
    )

}

export default Dashboard;