import React from 'react'
import "./Css/home.css"

const Home = () => {

    return (
        <>
        <div className='main'>

        <div className='bodywraper'>
        <div className="container">
        <div className="hero-section">
            <h2>Inmycolony.com - Empowering Community Based Learning</h2>
            <p>Connecting education with real-world community needs</p>
        </div>

        <div className="steps-section">
            <div className="step">
                <h2 className="highlight">Getting Started</h2>
                <p>If you're a student, organization, tutor, or business looking to provide hands-on learning opportunities:</p>
                <p>📲 Download Telegram app → Search for <span className="bot-command">@InmycolonyBot</span> → Create Profile via bot Menu</p>
            </div>

            <div className="step">
                <h2 className="highlight">Event Management</h2>
                <p>Create events using <span className="bot-command">@InmycolonyBot</span> and share photos with captions for your latest event</p>
            </div>
        </div>

        <div className="faq-section">
            <h2 style={{ textAlign: 'center', marginBottom: '2rem', color: '#FFD166' }}>Frequently Asked Questions</h2>
            
            <div className="faq-item">
                <div className="faq-question">What is community-based learning (CBL)?</div>
                <div>An educational approach connecting classroom learning with practical community experiences. Students collaborate with organizations and businesses to address real needs while gaining valuable skills.</div>
            </div>

            <div className="faq-item">
                <div className="faq-question">Advantages of CBL</div>
                <div>
                    <ul style={{ listStyle: 'none' }}>
                        <li>🌟 Experiential Learning through real projects</li>
                        <li>🌍 Enhanced Social Awareness</li>
                        <li>🤝 Improved Collaboration skills</li>
                        <li>🚀 Personal Growth & Civic Engagement</li>
                    </ul>
                </div>
            </div>

            <div className="faq-item">
                <div className="faq-question">CBL Examples</div>
                <div>Local clean-up drives, tutoring programs, cultural preservation projects, and business partnerships. Farmers sharing sustainable practices or artisans teaching traditional crafts.</div>
            </div>
        </div>
    </div>
        </div>
        <footer>
    <div>
      <a href="mailto:ivdmahesh@gmail.com">Email: ivdmahesh@gmail.com</a>
    </div>
    <div>
      <a href="tel:+919849502694">Phone: +91-9849502694</a>
    </div>
                Copyright © 2025
  </footer>
        </div>
        </>
    )

}

export default Home;